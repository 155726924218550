import React from "react"
import RavishRajRoll from "../components/ravishRajRoll"
import NavigationBar from "../components/Navbar"
import { Helmet } from "react-helmet"
import loadable from "@loadable/component"

const Scroll = loadable(() => import("../components/scroll"))
const Footer = loadable(() => import("../components/footer3"))

export default class RavishKumarPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <meta charSet="utf-8" />
          <title>Ravish Kumar </title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            name="description"
            content="Learn well-designed machine learning content written by Ravish Kumar."
          />
        </Helmet>
        <div className="sm:sticky top-0 z-50">
          <NavigationBar />
        </div>

        <div className="bg-light-white">
          <div className="container bg-light-white mx-auto px-6 lg:px-8 xl:px-10 pt-5 md:pt-8 lg:pt-10">
            <div className="md:grid md:grid-cols-12">
              <div className="md:col-span-11 md:text-left md:text-left">
                <h1 className="tracking-wider text-xl lg:text-2xl xl:text-3xl text-gray-900 text-center md:text-left">
                  Hi, I am Ravish Kumar
                </h1>

                <div className="text-left tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600 text-left md:text-left">
                  <p className="mt-3">
                    Machine Learning and Deep Learning expert | EnjoyAlgorithms
                    | Deeplite | IIT Kanpur | Super 30 | Educator | Passionate
                    about using AI for everyday life | Other skills and
                    interests: Computer Vision, Neural Networks, MLOps, Python,
                    DSA, Physics, Mathematics.
                  </p>

                  <p className="mt-3">
                    I am a guide who helps tech professionals master computer
                    science and build next-generation technology. I continuously
                    strive to simplify AI and ML concepts, helping tech
                    professionals implement industrial projects. My guiding
                    principles are patience, continuous learning and
                    improvement, collaboration, and thinking of out-of-the-box
                    solutions.
                  </p>

                  <p className="mt-3">Email: ravish@enjoyalgorithms.com</p>
                  <p className="mt-3"></p>
                </div>
                <div className="flex justify-end">
                  <a
                    href="https://www.linkedin.com/in/ravishkumar2/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex py-3 underline hover:text-new-blue"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-external-link"
                    >
                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                      <polyline points="15 3 21 3 21 9"></polyline>
                      <line x1="10" y1="14" x2="21" y2="3"></line>
                    </svg>
                    <p className="ml-2 tracking-wider text-base xl:text-lg">
                      Linkedin
                    </p>
                  </a>
                  <a
                    href="https://medium.com/@ravishraj"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex ml-4 py-3 underline hover:text-new-blue"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-external-link"
                    >
                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                      <polyline points="15 3 21 3 21 9"></polyline>
                      <line x1="10" y1="14" x2="21" y2="3"></line>
                    </svg>
                    <p className="ml-2 tracking-wider text-base xl:text-lg">
                      Medium
                    </p>
                  </a>
                </div>
              </div>
            </div>

            <h1 className="tracking-wider text-xl lg:text-2xl 2xl:text-3xl pt-8 text-center text-gray-900">
              <span className="">Ravish Kumar Blogs</span>
            </h1>

            <section>
              <div className="content mt-8">
                <RavishRajRoll />
              </div>
            </section>
            <Scroll showBelow={250} />
            <Footer />
          </div>
        </div>
      </React.Fragment>
    )
  }
}
